/* Home.css */
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1{
  color: #34495e;
  font-size: 2em;
  text-align: center;
  margin-bottom: 1rem;
}

.photo-wrapper {
  margin: 20px 0;
  text-align: center;
  width: 80%;
  border-radius: 8px;
}

.responsive-photo {
  max-height: 50%;
  border-radius: 8px;
}



.highlight {
  font-weight: bold;
  font-size: larger;
  color: #2c3e50; /* Customize the highlight color */
}

.contact-link {
  font-size: 2rem;
  color:#e67e22;/* Customize the link color */
  text-decoration: none;
  font-weight: bold;
}

.contact-link:hover {
  text-decoration: underline;
  color: #2980b9; /* Customize the link hover color */
}

.about-container {
    display: flex;
    flex-direction:column;
    justify-items: center;
  max-width: auto;
    padding: 1rem;
    color: #333;
    line-height: 1.6;
  }
  
  .about-container h2 {

    text-align: center;
   
  
    color: #2c3e50;
  }
  
  .about-container p {
    font-size: 1.2rem;
 
    text-align: center;
  }

  .blurb{
margin-bottom: 1.5rem;
margin-left: 1.5rem;
margin-right: 1.5rem;
    background-color: transparent;
    border-radius: 2px;
   display: inline-block;
    padding: 4px;
 
   
  }
  
  .about-container p span {
    font-weight: bold;
  }
  
  .about-container .highlight {
    color: #db6c30;
  }
  

.photo-wrapper {
  text-align: center;
  margin: 1rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}


.responsive-photo {
margin-top: 1.5rem;
  width: 70%; /* Make the image responsive */
  max-width: 500px; /* Ensure the image doesn't exceed 400px */
  max-height: 600px;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the entire image fits within the container */
  border-radius: 2px; /* Optional: Make corners slightly rounded */
}

@media (max-width: 600px) {
  .photos-container {
    flex-direction: column;
    align-items: center;
  }

  .photo-wrapper {
    
  }
}

.contact-container{
  margin-top: 1rem;
  text-align: left;
  margin: 1.5rem;
  font-size: smaller;
}

.success-message{
  font-size: smaller;
}

/* General Container Styling */
.services-container {
  align-items: center;
  display:block;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
 
 
}

/* Header Styling */
.services-container h1 {
  text-align: center;

  color: #34495e; /* Slightly darker color for header */
  margin-bottom: 40px;
  
}

/* Service Item Styling */
.service-item {
  text-align: left;
  margin: 1rem;
  padding: 5px;
  border-bottom: 1px solid #ecf0f1; /* Light gray border for separation */
 font-size: 1.2rem;
  color: #2c3e50; /* Darker color for text */
}

.service-item h1 {
  text-align: center;
  margin: 1rem;
  padding: 5px 0;
  border-bottom: 1px solid #ecf0f1; /* Light gray border for separation */
  font-size: 1em;
  color: #2c3e50; /* Darker color for text */
}

.service-item:last-child {
  
  border-bottom: none; /* Remove border for the last item */
}

.service-item span {
  text-align: center;
  font-weight: bold;
  color: #e67e22; /* Orange color for emphasis */
}

/* Responsive Design */
@media (max-width: 768px) {
  .services-container {
    padding: 10px; 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
   
  }

  .services-container h1 {
    margin-top: 1rem;
    font-size: 2em;
  }

  .service-item {
    font-size: 1.1rem;
   
  }
}

@media (max-width: 480px) {
  .about-container{
    .about-container {
      display: flex;
      flex-direction: column;
      justify-items: center;
     
    max-width: 90%;
      padding: 10px;
      color: #333;
      line-height: 1.6;
    }
    
    .contact-container-title{
      font-size: 1em;
    }
    .services-container {
      font-size: 1em;
    }
   
  }
  .services-container {
    font-size: medium;
    padding: 15px;
  }


  .service-item {
    font-size: 1em;
  }
}

/* General Container Styling */
.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Header Styling */
.contact-container h2 {
  text-align: center;
  font-size: 1em;
  color: #34495e; /* Dark color for header */
  margin-bottom: 30px;
 
}

/* Form Styling */
.contact-container form {
  display: flex;
  font-size: medium;
  flex-direction: column;
  gap: 20px; /* Space between form elements */
}

/* Form Input Styling */
.contact-container input[type="text"],
.contact-container input[type="email"],
.contact-container input[type="tel"],
.contact-container input[type="number"],
.contact-container input[type="submit"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #bdc3c7; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.contact-container input[type="submit"] {
  background-color: #e67e22; /* Button color */
  color: #ffffff; /* Text color */
  border: none;
  cursor: pointer;
  font-size: 1.1em;
}

.contact-container input[type="submit"]:hover {
  background-color: #d35400; /* Darker button color on hover */
}

/* Checkbox Group Styling */
.contact-container div p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.contact-container label {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between checkbox and label text */
  font-size: 1em;
}

.contact-container input[type="checkbox"] {
  accent-color: #e67e22; /* Checkbox color */
}

/* Success and Error Message Styling */
.success-message,
.error-message {
  text-align: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px; /* Rounded corners */
  font-size: 1em;
}

.success-message {
  color: #27ae60; /* Green for success */
  background-color: #eafaf1; /* Light green background */
  border: 1px solid #27ae60; /* Border matching text color */
}

.error-message {
  color: #c0392b; /* Red for errors */
  background-color: #fbecec; /* Light red background */
  border: 1px solid #c0392b; /* Border matching text color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    max-width: 80%;
    padding: 20px;
  }

  .contact-container h2 {
    font-size: 1em;
  }

  .contact-container input[type="submit"] {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 15px;
  }

  .contact-container h2 {
    font-size: 1em;
  }

  .contact-container input[type="submit"] {
    font-size: 0.9em;
  }
  
}

/* Contact Link Styling */
.contact-link {
  text-align: center;
  margin-top: 40px;
}

.contact-link a {
  color: #e67e22; /* Match with your theme color */
  text-decoration: none;
  font-weight: bold;
}

.contact-link a:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}

.home-logo {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 5rem; /* Space above the logo */
  max-width: 100%; /* Make the logo responsive */
  height: auto; /* Maintain aspect ratio */
}